import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        lng: 'sv', // Default language
        fallbackLng: 'en',
        interpolation: { escapeValue: false },
    });
    i18n.addResourceBundle('sv', 'translation', {'shop-ContinueShopping': 'Fortsätt Shoppa'});
    i18n.addResourceBundle('en', 'translation', {'shop-ContinueShopping': 'Continue Shopping'});
    i18n.addResourceBundle('sv', 'translation', {'shop-AddedProducts': 'Tillagda produkter'});
    i18n.addResourceBundle('en', 'translation', {'shop-AddedProducts': 'Added products'});
    i18n.addResourceBundle('sv', 'translation', {'shop-ClaspType': 'Låstyp'});
    i18n.addResourceBundle('en', 'translation', {'shop-ClaspType': 'Clasp'});
    i18n.addResourceBundle('sv', 'translation', {'shop-Colour': 'Färg'});
    i18n.addResourceBundle('en', 'translation', {'shop-Colour': 'Colour'});
    i18n.addResourceBundle('sv', 'translation', {'shop-ShowMore': 'Visa fler'});
    i18n.addResourceBundle('en', 'translation', {'shop-ShowMore': 'Show more'});
    i18n.addResourceBundle('sv', 'translation', {'shop-Discount': 'Ange rabattkod'});
    i18n.addResourceBundle('en', 'translation', {'shop-Discount': 'Enter discount code'});
    i18n.addResourceBundle('sv', 'translation', {'shop-Message': 'Meddelande'});
    i18n.addResourceBundle('en', 'translation', {'shop-Message': 'Message'});
    i18n.addResourceBundle('sv', 'translation', {'shop-TotalProducts': 'Summa produkter'});
    i18n.addResourceBundle('en', 'translation', {'shop-TotalProducts': 'Total products'});
    i18n.addResourceBundle('sv', 'translation', {'shop-Email': 'Email'});
    i18n.addResourceBundle('en', 'translation', {'shop-Email': 'Email'});
    i18n.addResourceBundle('sv', 'translation', {'shop-EmailList': 'Vill du stå på listan?'});
    i18n.addResourceBundle('en', 'translation', {'shop-EmailList': 'Do you want to be on the list?'});
    i18n.addResourceBundle('sv', 'translation', {'shop-EmailListText': 'Bli först med att få information kring nya kollektioner, tävlingar och erbjudanden.'});
    i18n.addResourceBundle('en', 'translation', {'shop-EmailListText': 'Be the first to receive information about new collections, competitions and offers.'});
    i18n.addResourceBundle('sv', 'translation', {'shop-IncomingText': 'Notera längre leveranstid, skickas så snart varan inkommit till lager.'});
    i18n.addResourceBundle('en', 'translation', {'shop-IncomingText': 'Please note longer delivery time, shipped as soon as the item arrives in stock.'});
    i18n.addResourceBundle('sv', 'translation', {'shop-IncomingContact': 'Kontakta oss om du önskar mer information.'});
    i18n.addResourceBundle('en', 'translation', {'shop-IncomingContact': 'Contact us if you would like more information.'});

export default i18n;